$font-stack:    Helvetica, sans-serif;
$primary-color: #333;

body {
  font: 100% $font-stack;
  color: $primary-color;
  background-color: powderblue;
  border-style: groove;
  padding: 25px;
}

.scale
{
    height: 2em; 
    width: 2em;
}

.scale2
{
    height: 4em; 
    width: 4em;
}

.scale4
{
    height: 8em; 
    width: 8em;
}

.box {
    vertical-align: middle;
}


.box200 {
  background-color: powderblue;
  padding: 10px;
  margin: 20px;
  width: 150px;
  text-align: center;
}

.box200-border {
  background-color: powderblue;
  border-style: groove;
  padding: 10px;
  margin: 20px;
  width: 200px;
  text-align: center;
}

.box250 {
  background-color: powderblue;
  border-style: groove;
  padding: 10px;
  margin: 20px;
  width: 250px;
  text-align: center;
}

.box300 {
  background-color: powderblue;
  border-style: groove;
  padding: 10px;
  margin: 20px;
  width: 300px;
  text-align: center;
}

.box350 {
  background-color: powderblue;
  border-style: groove;
  padding: 25px;
  padding: 10px;
  margin: 20px;
  width: 350px;
  text-align: center;
}

.box400 {
  background-color: powderblue;
  border-style: groove;
  padding: 25px;
  padding: 10px;
  margin: 20px;
  width: 400px;
  text-align: left;
}

.box400-noborder {
  background-color: powderblue;
  padding: 25px;
  padding: 10px;
  margin: 20px;
  width: 400px;
  text-align: left;
}

.flex-parent-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-child-400px {
  flex: 1 1 400px;
}

th, td {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

a { text-decoration: none; color:darkblue; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; background-color:slategrey }
a:focus { text-decoration: none; }

